<template>
  <div>
    <layout>
      <template #title>{{ $t('task.任务') }}</template>
      <template #right>
        <img
          @click="$refs.popup.show()"
          class="tip"
          src="@/assets/icon/tip.png"
          alt=""
        />
      </template>
      <template #content>
        <div class="content bcolor">
          <div class="info ml32 mr32">
            <div class="top">
              <div class="flex_row_space-between bcolor">
                <div>{{ $t('task.我的余额') }}</div>
                <div class="flexbox center">
                  <span>{{ $t('task.增加订单数量') }}</span>
                  <div class="snajiao ml10"></div>
                </div>
              </div>
              <div class="bcolor mt32">
                <span class="f38 bold">4.8899803209</span> <span>USDT</span>
              </div>
            </div>
            <div class="bottom flexbox">
              <div class="flex1 flexbox_column a-center">
                <div>{{ $t('task.今日剩余订单') }}</div>
                <div class="mt20">
                  <span class="f28">0</span>
                  <span class="ccolor"> {{ $t('task.频率') }}</span>
                </div>
              </div>
              <div class="flex1 flexbox_column a-center">
                <div>{{ $t('task.今日剩余订单') }}</div>
                <div class="mt20">
                  <span class="f28">0</span>
                  <span class="ccolor">{{ $t('task.频率') }}</span>
                </div>
              </div>
              <div class="flex1 flexbox_column a-center">
                <div>{{ $t('task.今日剩余订单') }}</div>
                <div class="mt20">
                  <span class="f28">0</span>
                  <span class="ccolor">{{ $t('task.频率') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="type">
          <div class="tab ccolor f38 flexbox">
            <div
              v-for="(item, i) in tabList"
              :key="item"
              class="flex1 flexbox vcenter tabItem"
              :class="[activeIndex === i ? 'active' : 'ccolor']"
              @click="changeTab(i)"
            >
              {{ item }}
            </div>
          </div>
          <div class="card ml32 mr32 hide">
            <div class="title">Manual {{ $t('task.手动交易') }}</div>
            <div class="shouyi">
              <div class="flex_row_space-between pb30">
                <div class="ml32">{{ $t('task.预期收益') }}</div>
                <div class="mr32">{{ $t('task.需要启动存款') }}</div>
              </div>
              <div class="flex_row_space-between pb30">
                <div class="ml32">
                  <span class="f38">0.998-0.9786</span>
                  <span class="ccolor ml10">USDT</span>
                </div>
                <div class="mr32">
                  <span class="f38">0</span
                  ><span class="ccolor ml10">USDT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <popup center ref="popup">
      <div class="poptip">
        <img class="img" src="@/assets/icon/tipimg.png" alt="" />

        <div class="f30 mt30" style="color: #fff">
          {{ $t('task.任务说明') }}
        </div>
        <div class="f30 mt10 ccolor">
          1. {{ $t('task.每天可以完成10个任务') }}
        </div>
        <div class="f30 mt10 ccolor">
          2.{{ $t('task.联系VIP助手增加任务数量') }}
        </div>
        <div class="f30 mt10 ccolor">
          3. {{ $t('task.每个用户的任务总数为300次') }}
        </div>
        <div class="f30 mt10 ccolor">
          4. {{ $t('task.购买机器人无需人工操作即可自动交易') }}
        </div>
        <div
          class="flexbox j-end mt20 f30"
          style="color: #fff"
          @click="$refs.popup.hide()"
        >
          {{ $t('task.我知道了') }}
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import popup from '@/components/popup/index.vue'
export default {
  components: {
    popup
  },
  data() {
    return {
      tabList: ['项目清单', '订单日志'],
      activeIndex: 0
    }
  },
  methods: {
    changeTab(i) {
      this.activeIndex = i
    }
  }
}
</script>

<style lang="scss" scoped>
.tip {
  width: 38px;
  height: 38px;
}
.content {
  .info {
    border-radius: 28px;
    background-color: #2a2c2e;
    overflow: hidden;
    .top {
      background-color: #0d9135;
      padding: 32px;
      border-radius: 28px;
      .snajiao {
        border-width: 6px;
        border-color: transparent transparent transparent #000;
        border-style: dotted dotted dotted solid;
      }
    }
    .bottom {
      padding: 32px 0;
    }
  }
  .type {
    background-color: #202020;
    padding: 30px 0;
    border-radius: 28px 28px 0 0;
    margin-top: 30px;
    .tab {
      .tabItem {
        padding: 20px 0;
      }
      .active {
        color: #fff;
      }
    }
    .card {
      border-radius: 28px;
      .title {
        background-color: #857332;
        padding: 20px 32px;
      }
      .shouyi {
        background-color: #2e2e2e;
      }
    }
  }
}
.poptip {
  margin-left: 32px;
  margin-right: 32px;
  /* height: 442px; */
  background: #2a2c2e;
  position: relative;
  padding: 20px 30px;
  border-radius: 28px;
  .img {
    width: 104px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-0%, -50%);
  }
}
</style>
