import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import zh from './zh'
import tr from './tr'
import id from './id'
import kr from './kr'
import th from './th'
import ar from './ar'
import bn from './bn'
import es from './es'
import hi from './hi'
import pt from './pt'
import jp from './jp'
Vue.use(VueI18n)

const messages = {
  tr: {
    ...tr
  },
  hi: {
    ...hi
  },
  bn: {
    ...bn
  },
  es: {
    ...es
  },
  pt: {
    ...pt
  },
  id: {
    ...id
  },
  jp: {
	...jp
  },
  th: {
    ...th
  },
  kr: {
    ...kr
  },
  ar: {
    ...ar
  },
  zh: {
    ...zh
  },
  en: {
    ...en
  }
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
})

export default i18n
