const state = {
  partnerList: [
    {
      icon: require('@/assets/icon/ouyi.png'),
      url: 'https://www.okex.com'
    },
    {
      icon: require('@/assets/icon/imtoken.png'),
      url: 'https://indodax.com/'
    },
    {
      icon: require('@/assets/icon/gote.png'),
      url: 'https://www.gate.io/en'
    },
    {
      icon: require('@/assets/icon/cion.png'),
      url: 'https://pro.coinbase.com/'
    },
    {
      icon: require('@/assets/icon/token.png'),
      url: 'https://www.tokenpocket.pro/en/submit/token'
    },
    {
      icon: require('@/assets/icon/polo.png'),
      url: 'https://poloniex.com/'
    },
    {
      icon: require('@/assets/icon/bitf.png'),
      url: 'https://www.bitfinex.com/'
    },
    {
      icon: require('@/assets/icon/bina.png'),
      url: 'https://www.binance.com/en'
    },
    {
      icon: require('@/assets/icon/huobi.png'),
      url: 'https://www.huobi.com/en-us/#exchange'
    }
  ]
}

export default {
  state
}
