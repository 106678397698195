export default {
	
	tabbar: {
	  首页: 'Página inicial',
	  市场: 'Pazar',
	  任务: 'görev',
	  团队: 'Takım',
	  我的: 'Benim.'
	},
	navbar: {
	  充值: 'Para Yatırma',
	  提现: 'Para Çekme',
	  帮助: 'Yardım',
	  分享: 'Paylaş',
	  活动: 'Etkinlik'
	},	 
	mayment: {
	  付款方式详情: 'Ödeme metodları',
	  银行卡: 'Banka kartı',
	  钱包地址: 'Cüzdanın adresi',
	  交易密码: 'İşlem Şifresi',
	  保存: 'kurtarmak'
	},
	market: {
	  市场: 'Pazar',
	  名称: 'Isim',
	  价格: 'Fiyat',
	},
	 
	task: {
	  任务: 'Görev',
	  语言切换: 'Dil anahtarı',
	  我的余额: 'Bakiyem',
	  增加订单数量: 'Sipariş miktarını artır',
	  今日剩余订单: 'Bugünün kalan siparişleri',
	  频率: 'frekans',
	  手动交易: 'Manuel ticaret',
	  预期收益: 'beklenen getiri',
	  需要启动存款: 'Para yatırmayı etkinleştirmeniz gerekiyor',
	  任务说明: 'Görev açıklaması',
	  每天可以完成10个任务: 'Her gün 10 görev tamamlanabilir',
	  联系VIP助手增加任务数量: 'Görev sayısını artırmak için VIP asistanıyla iletişime geçin',
	  每个用户的任务总数为300次: 'Kullanıcı başına toplam görev sayısı 300\'dür',
	  购买机器人无需人工操作即可自动交易: 'Manuel işlem gerektirmeden otomatik ticaret yapabilen bir robot satın alın',
	  我知道了: 'Anladım'
	},
	团队: 'Takım',
	 规则: 'Kural',
	 会员级别: 'Üyelik seviyesi',
	 经验: 'Deneyim',
	 代理信息: 'Ajan bilgisi',
	 更多: 'Daha fazla',
	 团队奖励: 'Takım ödülleri',
	 昨天: 'Dün',
	 本星期: 'Bu hafta',
	 全部: 'Tüm',
	 团队收入记录: 'Takım gelir kayıtları',
	 机器人回扣: 'Robot yeniden',
	 会员体验: 'Üye deneyimi',
	 个人收入记录: 'Kişisel gelir kayıtları',
	 机器人采购: 'Robot alması',
	 机器人启动押金: 'Robot depozit başlatır',
	 机器人礼物: 'Robot hediyesi',
	 机器人订单: 'Robot düzeni',
	 机器人: 'Robot',
	 一次性总和: 'Bir kere toplam',
	 总计: 'Toplam',
	 我的机器人: 'Robotum',
	 被激活: 'Etkinleştirildi',
	 工作中: 'Işte',
	 暂停: 'Suspend',
	 结束: 'Sona',
	 刷新: 'Tazele',
	 来源: 'kaynak',
	 价格: 'Ödül',
	 启动押金: 'Depozit başlat',
	 剩余时间: 'Kalan zamanı',
	 激活时间: 'Etkinleştirme Zamanı',
	 号: 'Numara',
	 我的资产: 'Varlıklarım',
	 退出: 'Çıkış Yap',
	 邀请码: 'Davet kodu',
	 我的钱包余额: 'Cüzdan bakiyem',
	 我的机器人仓库: 'Robot deposu',
	 代理信息: 'Ajan bilgisi',
	 去提款: 'Para Çekme',
	 昨天代理返利: 'Bugün Ajans Kazançları.',
	 累计代理返利: 'Ajans kümülatif geliri',
	 今天机器人收益: 'Bugün robot geliri',
	 累计机器人收益: 'Cumulative robot gelirleri',
	 累计机器人回扣: 'Kümülatif robot indirimi',
	 累计总回报: 'Toplam geri dönüş',
	 自动订单机器人数量: 'Otomatik robot sayısı',
	 我的付款方式: 'Ödeme yöntemi',
	 个人收入记录: 'Kişisel gelir kayıtları',
	 团队收入记录: 'Takım gelir kayıtları',
	 充值记录: 'Yeniden şarj rekoru',
	 我的团队成员: 'Takım üyeleri',
	 活动中心: 'Etkinlik Merkezi',
	 修改登录密码: 'Giriş parolanı değiştir',
	 交易密码管理: 'Transaksyon parola yönetimi',
	 机器人性能: 'Robot performansı',
	 机器人价格: 'Robot fiyatı',
	 启动押金: 'Robot fiyatı',
	 有效期: 'Gıymetlik terimi',
	 号: 'Numara',
	 天: 'Gün',
	 去购买: 'Satın alın',
	 买个机器人: 'Bir robot alın',
	 预计日收益: 'Tahmin edilen günlük gelir',
	 启动保证金: 'Depozit başlat',
	 请输入购买数量: 'Lütfen satın miktarını girin',
	 个机器人: 'Bir robot',
	 机器人购买后需要激活才能工作: 'Robot çalışmak için satın almaktan sonra etkinleştirilmeli.',
	 机器人激活需要从余额中扣除相应的启动押金:
	   'Robot aktivasyonu, ilgili başlangıç ​​depozitosunun bakiyeden düşülmesini gerektirir:',
	 机器人暂停机器人停止时将返还启动押金:
                 
	   'Robot duraklatıldığında ve robot durdurulduğunda başlangıç ​​depozitosu iade edilecektir.',
                   不激活的机器人可以赠送:
                   ' Etkin olmayan botlar şunları verebilir.',
	 购买: 'satın alın',
	 点击去了解我们: 'Bizi öğrenmek için tıklayın',
	 购买机器人: 'Robotlar alın.',
	 快速入口: 'Hızlı giriş',
	 '日/个人受益': 'Günlük / kişisel faydalar',
	 购买教程: 'Alın öğretim',

	 订单数量: 'Sıra miktarı',
	 我们的合作伙伴: 'Ortaklarımız',
	 提款: 'çizim',
	 提款金额: 'Kaldırma',
	 你还没有添加银行卡: 'Henüz bank a kartını eklemedin.',
	 提现说明: 'Kaçırma',
	 确认提款: 'Çıkarmayı onaylayın ',
	 钱包余额: 'Cüzdanım balanım',
	 输入数量:"Kimlik girin",
	 数量:"Numara",
	 登录: 'İmzala',
	 输入账号: 'Lütfen kullanıcı adını girin',
	 输入密码: 'Lütfen şifre giriniz',
	 还没有账号点击注册: 'Henüz bir hesabınız yok mu? Kayıt ol',
	 忘记密码: 'Şifrenizi mi unuttunuz?',
	 重新登录: 'Tekrar gir',
	 密码找回成功: 'Parola başarılı alındı',
	 找回成功请重新登录: 'Başarılıklı verildi. Lütfen tekrar girin',
	 确认: 'Onayla',
	 发送验证码: 'Denetim kodu gönder',
	 再次输入密码: 'Parola tekrar girin',
	 输入验证码: 'Denetim kodu girin',
	 输入手机号: 'Lütfen telefon numarasını girin',
	 注册: 'Kayıt',
	 输入用户名: 'Lütfen kullanıcı adını girin',
	
	 返回: 'Return',
	 密码找回失败: 'Parola alınması başarısız oldu',
	 输入的信息有误: 'Girdiği bilgi yanlış.',
		活动中心: 'Etkinlik Merkezi',
		获取验证码:"Denetim kodu alın",
		手机号码不能为空:"Cep telefonu numarası boş olamaz",
		账号不存在:"Hesap yok",
		发送成功:"Gönderilen başarıyla",
		今日收益:"Bugün kişisel gelir",
		有效期:"Geçerlilik süresi",
		日收益:"Bugün kulaklar",
		
		数量不能为空:"Kıymet boş olamaz",
		加载中:"Yükleniyor",
		唯一码:"Tek kodu",
		未激活:"İnaktif",
		激活:"Etkinleştirme",
		购买时间:"Alış zamanı",
		"钱包余额不足，激活该机器人失败":"Yetersiz cüzdan bakiyesi, robot aktivasyonu başarısız oldu",
		暂无数据:"Veri yok",
		激活时间:"Etkinleştirme Zamanı",
		运行时长:"Çalışma vakti",
		签约购买:"Anlaşma satış",
		系统赠送:"Sistem hediyesi",
		状态:"Durum",
		正常:"Normal",
		我的机器人:"Robotum",
		一级会员:"Birinci seviye üye",
		二级会员:"İkinci seviye üye",
		三级会员:"Üçüncü seviye üyesi",
		人:"İnsanlar",
		充值客服:"Müşteri hizmeti yeniden yükleyin",
		充值:"Tekrar yükle",
		提现:"Çıkarma",
		设置交易密码:"Transaksyon parolanı ayarlayın",
		登录密码:"Giriş paroli",
		请输入交易密码:"Lütfen transaksyon parolanı girin",
		再次请输入交易密码:"Lütfen transaksyon parolanı tekrar girin",		
		两次密码不一致:"İki parola uygunsuz.",
		
		操作成功:"Operasyon başarılı",	
		登录成功:"İçeri başarılı",
		充值说明:"Tekrar yükle tasvir",
		请先设置支付密码:"Lütfen ödeme şifresini ilk ayarlayın",
		复制成功:"Başarılı kopyalama",
		冻结机器人做单本金:"Robot'un müdürünü dondurun.",
		待审核:"Görüntülenmek için",
		成功:"Başarılı",
		失败:"Başarısız",
		审核中:"Gözlemde",
		在线充值:"Çevrimi yeniden yükleyin",
		描叙:"Tarif et",
		银行卡提现:"Banka kartı çekilmesi",
		USDT提现:"Usdt çekilme",
		三级代理:"Üç seviye ajanı",
		一级代理:"İlk ajan",
		二级代理:"İkinci ajan",
		做单:"Emri yap",
		团队返佣:"Takım Komisyonu",
		购买机器人返佣:"Robot Komisyonu alın",
		本金返还:"Müdür dönüşü",
		时间:"Zaman",
		机器人做单返还:"Robot emri yapıyor ve geri dönüyor",
		涨幅:"Arttır",
	 付款方式:"Ödeme yöntemi",
		我的留言:"Haberler",
	 
	 银行卡:'Banka kartı',
	 修改: 'Değiştir',
	'确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'Cüzdanın adresinin doğru olduğundan emin olun, yanlış adres girişi yüzünden parayı alamayan kullanıcının kaybını engellemek için.',
	连接借记卡:"Bağlam banka kartı",
	银行名称:'Banka adı',
	姓名姓氏: 'İsim Soyisim',
	IBAN号码:'Banka kartı numarası',
	'* 重要提示:借记卡信息必须真实有效才能取款。':'* Önemli: debet kartı bilgileri çıkarmadan önce doğru ve geçerli olmalı.',
	帮助中心:'Yardım merkezi',
	会员须知:'Üyelerin notları',
	'24小时内使用USDT实时取款（推荐）':'1. Bizimle 24 saat içinde gerçek zamanlı çekilme (önerildi)',
	'银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':" 2. Banka kartı çekilmesi * çekilmesi zamanı 10:00-18:00 * çekilmesi başlattıktan 2 saat içinde hesap girmesi * çekilmesi zamanı için uygulama ya da banka çekilmesi yarın gün 10:00-18:00'da hesaba ulaşacak",
	我知道了:'Anladım!',
	链接点击:'Bağlantı Klik',
	确定:'Belirle',
	建立自己的团队邀请:'Kendi takım davetinizi inşa edin',
	
	加入:'Join',
	"你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
		    "Satdığınız robot diğer parti tarafından kabul edildi ve satış gelirleri hesabınıza ödedi.Lütfen kontrol edin.",
		"你出售的机器人对方已拒绝接受":"Sattığınız robot diğer parti tarafından reddedildi.",
		向你赠送一台机器人:"Sana bir robot vermek için, onay bekleniyor",
		向你出售一台机器人:"Sana bir robot satmak için, onay bekleniyor",
		出售价格不能超过机器人价格:"Satış fiyatı robot fiyatını aşamaz.",
		银行卡已被绑定:"Banka kartı bağlandı.",
		USDT_TRC20提现:'USDT_TRC20Çıkarma',
		USDT_ERC20提现:'USDT_ERC20Çıkarma',
		邀请赠送:'Davet hediyesi',
		
		升级:'Güncelle',
		充值主网:'Ana ağı yeniden yükle',
	  "您被限制出售机器人,请联系客服了解详情":
	    "Robot satmanız kısıtlandı, lütfen ayrıntılar için müşteri hizmetleri ile iletişime geçin",
	  交易编号: "Numara",
	  消息详情:"Mesaj detayları",
	  个人收益累计:"Birikmiş kişisel gelir",
	  今日代理收益:"Bugünkü temsilci kazançları",
	  代理累计收益:"Temsilci kümülatif geliri",
	  机器人返佣:"Robot Komisyonu",
	  截图保存推荐给朋友:"Ekran görüntüsünü kaydedin ve bir arkadaşınıza önerin",
	  复制:"Kopyala",
	  充值提示1:"1. tipi: 30 saat boyunca en az yeniden yüklenmek",
	  充值提示2:"2. tipi: 10usdt'in en az yeniden yüklenmesinden yeniden yükleyin",
	  请输入正确的充值金额:"Lütfen doğru yatırma miktarını girin",
	  推荐:"Tavsiye et",
	  充值金额:"Tekrar yükle miktarı",
	  请上传付款截图:"Lütfen ödeme ekran fotoğrafını yükleyin",
	  充值ID提示:"Kimlik sorgulaması yeniden yükle",
	  "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Lütfen cüzdanın adresinin doğru olduğunu kontrol edin. Eğer cüzdanın adresi yanlış şekilde doldurulsa, kullanıcı parayı toplamaktan başarısız olduğu kayıpları taşıyacak.",
		上传凭证:"Yükleyici",
		不能为空:"Boş olamaz",
	 绑定钱包地址:'Cüzdanın adresi bağla',
		请输入交易密码:"Lütfen transaksyon parolanı girin",
		备注:"Açıklama",
		请输入你的名字:"Lütfen adınızı giriniz",
		请输入银行卡号:"Lütfen banka kartı numarasını girin",
		银行卡号:"Banka kartı numarası",
		添加银行卡:"Banka kartı ekle",
		银行:"Banka",
		请选择银行:"Lütfen bir banka seçin",
		请输入钱包地址:"Lütfen cüzdan adresini girin",
		钱包地址:"Cüzdan Adresi",
		"取款须知":"Çıkarma talimatları",
		"须知1":"1. Bizimle 24 saat içinde gerçek zamanlı çekilme (önerildi)",
		"须知2":"2. Banka kartı",
		"须知3":"Çıkarma vakti 10:00-18:00.",
		"须知4":"*Çıkarma zamanı veya banka aracılığı çıkarma uygulaması sonraki gün 10:00'dan 18:00'a ulaşacak.",
		
		"提现0":"1USDT=1USD=₺13.8",
		"提现1":"İpucu 1: ERC20'de minimum para çekme miktarı 30 USDT ve tek seferlik işlem ücreti 10 USDT'dir.",
		"提现2":"İpucu 2: TRC20'de minimum para çekme miktarı 10 USDT ve tek seferlik işlem ücreti 1 USDT'dir.",
		"提现3":"İpucu 3: Bir banka hesabından tek çekim için minimum tutar 10 USDT'dir (₺138). Tek çekim ücreti, çekim tutarının %6'sidir.",
		
		请输入请款金额:"Lütfen istenilen miktarı girin",
		已绑定:"Bağlandı",
		去设置:"Ayarlara git",
		汇率:"Döviz kuru",
		请输入正确的提现金额:"Lütfen doğru para çekme tutarını girin​",
		提款记录:"Çekilme kayıtları",
		"注册账号长度范围为5~20":"Kayıtlı hesabın uzunluğu 5 ile 20 arasında olmalıdır",
		已过期:"Süresi geçmiş",
		暂停中:"Durdurulmuş",
		"用户名或密码不正确,登录失败":"Yanlış oturum açma şifresi veya hesabı",
		"该USDT钱包已经被绑定，请更换USDT钱包	USDT": "cüzdanı bağlandı, lütfen USDT cüzdanını değiştirin",
		"密码错误次数过多,请等待x秒后再试":	"Çok fazla yanlış şifre girildi, lütfen x saniye bekleyin ve tekrar deneyin",
		银行卡号必须是数字:"Banka kartı numarası bir sayı olmalıdır",

		指定用户不存在:"Belirtilen kullanıcı mevcut değil",
		手机号码已存在:"Cep telefonu numarası zaten var",
		注册账号不能为空:"Kayıt hesabı boş bırakılamaz",
		注册账户不能为空:"Kayıt hesabı boş bırakılamaz",
		手机号码不能为空:"Telefon numarası boş bırakılamaz",
		请输入原始密码	:"Lütfen orijinal şifreyi girin",
		两次密码不一致	:"İki şifre eşleşmiyor",
		"请输入完整的信息!":"	Lütfen bilgileri eksiksiz girin!",
		手机号格式错误	:"Yanlış telefon numarası formatı",
		该机器人只能购买一次	:"Bu robot yalnızca bir kez satın alınabilir",
		"请输入完整信息!":	"Lütfen bilgileri eksiksiz giriniz!",
		邀请码不正确	:"Davet kodu yanlış",
		验证码错误	:"Doğrulama kodu hatası",
		图片验证码已经失效	:"Resim doğrulama kodunun süresi doldu",
		请输入验证码:"	Lütfen doğrulama kodunu girin",
		注册账号已存在	:"Hesap zaten kayıtlı var",
		两次密码输入不一致	:"Tutarsız şifre iki kez girildi",
		请输入邀请码	:"Lütfen davetiye kodunu girin",
		请输入确认密码	:"Lütfen şifreyi onaylayın",
		账户usdt不足	:"Hesap yetersiz usdt",
		购买成功:"	Satın alma başarılı",
		激活成功	:"Etkinleştirme başarılı",
		复制成功	:"Kopyalama başarılı",
		已成功帮您暂停该机器人	:"Bot sizin için başarıyla duraklatıldı",
		余额不足:"	Yetersiz bakiye",

		导师热线:"Öğretmen Otelini",
		请输入密码:"Lütfen bir parola girin",
		请输入账号:"Lütfen hesap numarasını girin",
		"您的余额不足，无法接受该出售申请，请确保余额充足后再操作":"Bakiyeniz satış başvurusunu kabul etmek için yetersiz, lütfen devam etmeden önce bakiyenin yeterli olduğundan emin olun.",
		"您是否接受该申请？如果接受申请将扣除您账户内对应金额。":"Bu uygulamayı kabul ediyor musunuz? Başvuru kabul edilirse, hesabınızdan ilgili tutar düşülecektir.",
		您收到一条机器人出售回复:"Satılık robot yanıtı aldınız",
		您收到一条待确认申请:"Bekleyen bir isteğiniz var",
		"激活成功，预计5分钟左右开始工作":"Etkinleştirme başarılı ve yaklaşık 5 dakika içinde çalışmaya başlaması bekleniyor.",
		"停止工作，本金已返还至余额":"Çalışma durdu, ana para bakiyeye eklendi",
		激活金额:"Etkinleştirme tutarı",
		kefu1:"Youtube video eğitimi",
		团队规则:"Takım kuralları",
		
		恭喜新用户:"Congratulations to new users",
		取消:"Cancelar",
		请输入短信验证码:"Lütfen SMS doğrulama kodu girin",
		注册成功:"Giriş başarılı oldu",
		付款方式:"Benim çantam",
		"赠送/出售":"Hediye etmek / satmak",
		"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":"Karşı tarafın kullanıcı adını girin ve lütfen transfer ederken bilgileri dikkatlice inceleyin. Bir hata olursa müşteri hizmetleri aracılığıyla geri alınamaz.",
		请输入用户ID:"Lütfen kullanıcı girin ID",
		赠送:"Hediye etmek",
		出售:"Satmak",
		请输入出售金额:"Lütfen satış miktarını girin",
		出售待确认:"Tahmin edilecek satış",
		"向你赠送一台机器人，待确认":"Sana bir robot vermek için, onay bekleniyor",
		"出售/赠送人":"Satılan / Hediye Edilen",
		"出售/赠送": "Satılan / Hediye Edilen",
		已接受:"Kabul Edildi",
		已拒绝:"Red edildi",
		已过期:"Yetişmiş",
		快捷充币:"Hızlı Mevduat",
		拒绝	:"Reddet", 
		接受	:"Kabul Et",
		那收到一条待确认申请	:"Bekleyen bir başvuru alındı",
		"出售待确认中,请勿重复操作":"Satışınız onay bekliyor, lütfen işlemi tekrarlamayın",
		出售中:"Satışta",
		去设置:"Ayarlar",
		会员出售:"Memur satışı",
		不支持出售:"Satış desteklenmiyor",
		交易密码不正确:"Yanlış işlem şifresi",
		修改绑定USDT钱包请联系客服:"USDT cüzdanını değiştirmek ve bağlamak için lütfen müşteri hizmetleri ile iletişime geçin.",
		
		
		投资:"Yatırım",
		投资理财:"yatırım ve finansal yönetim",
		投资金额:"Yatırım Tutarı",
		我的投资:"Yatırımlarım",
		投资返佣:"yatırım indirimi",
		投资订单:"Yatırım Emri",
		运行中:"Çalışıyor",
		冻结投资做单本金:"Dondurulan Yatırım Emri Esası",
		投资做单返还:"Yatırım emirlerinin getirisi",
		"钱包余额不足，启动投资失败":"Cüzdan Bakiyesi Yetersiz, Yatırım Başlatılamadı",
		收到一个系统赠送的投资产品:"Sistemin Verdiği Yatırım Ürününü Aldı",
		购买投资:"Satın Alma Yatırımı",
		购买投资增加经验值:"Satın alma yatırımı deneyim puanlarını artırdı",
		下级购买投资增加经验值:"Astların Satın Aldığı Yatırım Artan Tecrübe Puanı",
		出售价格不能超过投资价格:"Satış fiyatı yatırım fiyatını geçemez",
		"向你赠送一个投资产品，待确认":"Size Onay Bekleyen Bir Yatırım Ürünü Verin",
		"向你出售一个投资产品，待确认":"Size Bir Yatırım Ürünü Satmak, Onay Bekliyor",
		"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收":"Sattığınız yatırım ürününü karşı taraf kabul etmiş olup, satıştan elde edilen gelir hesabınıza aktarılmıştır. Lütfen kontrol ediniz",
		你出售的投资产品对方已拒绝接受:"Karşı taraf sattığınız yatırım ürününü kabul etmedi",
		赠送投资:"Bedava yatırım",
		投资产品:"Yatırım ürünü",
		请选择投资产品:"Lütfen bir yatırım ürünü seçin",
		自动投资产品数量:"Otomatik yatırım ürünü sayısı",
		
		"youtube活动内容":"Bonuslarla İlgili Önemli Açıklama:\n1. Kullanıcılar, bir video kaydedip YouTube, Facebook gibi kanallarına yükleyerek 1 ila 200 dolar arasında bir bonus alabilirler.\n2. Platform, videonuzu inceleyecek ve video kalitesine göre reklam ücretini hesap bakiyenize yatıracaktır.\n3. Aynı kanal ve aynı reklam videosu sadece bir kez bonus alabilir, ancak her hafta uygulama için yeni videolar yayınlanabilir.\n4. Kötü niyetli video ödül talepleri yasaktır. Kötü niyetli video ödül taleplerinde bulunursanız, hesabınız yasaklanacaktır.",
		"Bono de YOUTUBE":"YouTube bonusu",
		"Publicar video bono 10 USDT - 200 USDT":"Herkese açık video bonosu 10 USDT - 200 USDT",
		"Ingrese el monto de la solicitud":"Lütfen talep tutarını girin",
		"Enlace de tu video":"Video bağlantınız",
		"Ingrese el enlace del video post-publicado":"Yayın sonrası videonun bağlantısını girin",
		"Aplicar para un premio":"Ödül başvurusu",
		"Solicitar una bono":"Kupon İste",
		"URL del vídeo":"video adresi",
		"Tiempo de aplicación":"Uygulama zamanı",
		"个人充值红利活动":"Kişisel şarj bonusu etkinliği",
		
		"注册证书":"Tescil Belgesi",
		
}