<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('注册证书') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <img src="@/assets/img/certificate1.jpg">
      <img src="@/assets/img/certificate2.jpg">
    </div>
	
  </div>
</template>

<script>
export default {
  name: 'welcome',
  components: {
  },
  data() {
    return {
      
    }
  },
	 created(e) {
	    var token = localStorage.getItem("key");
	    console.log("token", token);
	    if (!token) {
	      this.$router.replace("/login");
	    }
	    this.token = token;
	  },
  methods: {
    getBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 24px 40px 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    img{
		width: 100%;
	}
  }
}
</style>
