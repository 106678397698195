<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('充值') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="mt30">
     
      
          <div class="contnet">
            <div class="card">
              

              <p style="color: #0d9135; font-size: 10px" >
                {{$t('银行名称')}}{{ listbank.bankname }}
              </p>
              <van-button
                :data-clipboard-text="listbank.bankname"
                class="clipboardBtnUrl determine"
                style="width: 100px; height: 25px"
                @click="onCopy($t('复制成功'))"
              >
                {{ $t('复制') }}
              </van-button>
			<p style="color: #0d9135; font-size: 10px">
			  {{$t('姓名姓氏')}}{{ listbank.banktype }}
			</p>
			<van-button
			  :data-clipboard-text="listbank.banktype"
			  class="clipboardBtnUrl determine"
			  style="width: 100px; height: 25px"
			  @click="onCopy($t('复制成功'))"
			>
			  {{ $t('复制') }}
			</van-button>
			<p style="color: #0d9135; font-size: 10px">
			  {{$t('银行卡')}}{{ listbank.banknum }}
			</p>
			<van-button
			  :data-clipboard-text="listbank.banknum"
			  class="clipboardBtnUrl determine"
			  style="width: 100px; height: 25px"
			  @click="onCopy($t('复制成功'))"
			>
			  {{ $t('复制') }}
			</van-button>
             
              <p class="text">{{ $t('充值金额') }}</p>
              <van-field class="input" v-model="money" />
             <p class="text">{{$t('备注')}}</p>
              <van-field
                class="input"
                v-model="hash"
                :placeholder="$t('备注')"
              />
              <p class="text">{{ $t('请上传付款截图') }}</p>
              <div class="rob-foot-flex">
              		    <input type="file" accept="image/*" @change="changeImage()"  ref="avatarInput"  style="display:none">
              		  </div>
			  <img src="../../assets/upload.jpg" style="width: 100px;height: 100px;" @click="upLoad">
			  <div class="text">
			  <div class="pic_list_box" v-if="img">
			   <img :src="img" width="100%">
			   </div>
			  </div> 
              <div class="introduce" v-html="info.bank_xx_tip">
             
              </div>
			 
            </div>
          </div>
       
    
    </div>
	<van-popup v-model="paypwd" round closeable @close="closepaypwd" class="toastIndex">
		  <div class="wt-select-dialog" style=" height: 6.00097rem;text-align: center;background-color: #2a2c2e">
				  <br>
				   <br>
				  <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("联系客服获取验证码")}}</p>
		      <van-row >
				<div >
					<div class="wt-card" style="min-height: 50px; text-align: center">
					  <van-field
					    class="input"
					    v-model="code"
					    :placeholder="$t('请输入验证码')"
					  />
					</div>
		        <van-row span="10" style=" padding-top: 10px;" @click="getbank()">		
		          <van-button  type="info" style="width: 100px;background-color: #2a2c2e" >{{$t("获取")}}</van-button>
		        </van-row>
				</div>
		      </van-row>
		    </div>
		 
	</van-popup>
    <ROBOT></ROBOT>
     <HBBTN  @clickEvent="subchongzhi"> <span >{{ $t('确定') }} </span></HBBTN>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import axios from "axios";
import QS from "qs";
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2-fix";
import { NavBar, Progress, Icon, Button, Toast } from 'vant'

import {
  apichongzhi,
  getUrlKey,
  apichongzhiusdt,
  apiuploadimg,
  postimg,
  apigetHuilv,
  apiUser_mas2,
  getCodeBank,
  apichongzhirmb
} from '@/http/api/'
export default {
  name: 'welcome',
  components: {
    ROBOT,
    HBBTN
  },
  data() {
    return {
      name: '',
      userName: '',
      inputData: '32',
      active: 2,
      fileList: [], //{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }
      token: '',
      link: '',
      zhuwang: '',
      hash: '',
      money: '',
      dataimg: '',
      imgDatas:[],
	  img:"",
	  info:[],
	  paypwd:true,
	  code:"",
	  listbank:[]
    }
  },
  created() {
    const token = localStorage.getItem('key')
    console.log('token', token)
    if (token == null) {
      this.$router.replace('/login')
    }
	apiUser_mas2({
	  userid: token
	}).then((res) => {
	  if (res.status == 10) {
	    this.$router.replace('/login')
	  }
	  this.info = res.user  
	})
    var url = window.location.href
    let type = getUrlKey('type', url)
    let money = getUrlKey('money', url)
    this.money = money;
    this.token = token
    this.init(type)
    
  },
  methods: {
	  closepaypwd(){
		this.paypwd = false;  
	  },
	  getbank(){	  
		  Toast.loading({
		    duration: 0,
		    message: "",
		    forbidClick: true,
			className: 'toastIndex'
		  });
		  getCodeBank({
		  	yzmcode:this.code
		  }).then((res)=>{
		  	Toast.clear();	
			if(res.status == 0){
				Toast.fail({
				  message: this.$t(res.info),
				  className: 'toastIndex'
				})
				return
			}else{
				this.listbank = res.info
				this.paypwd = false;  
			}
		  })
	  },
	  subchongzhi(){
	  		// if(this.hash == ''){
	  		// 	Toast.fail("hash"+this.$t('不能为空'));
	  		// 	return;
	  		// }
	  		if(!this.dataimg){
	  			Toast.fail(this.$t('请上传付款截图'));
	  			return;
	  		}
	  		Toast.loading({
	  		  duration: 0,
	  		  message: "",
	  		  forbidClick: true,
	  		});
	  		apichongzhirmb({
	  			userid:this.token,
	  			remark:this.hash,
	  			money:this.money/this.info.rate,
	  			dataimg:this.dataimg,
				banknum:this.listbank.banknum
	  		}).then((res)=>{
	  			Toast.clear();
	  			if(res.status == 0){
	  				Toast.fail(res.info);
	  				return;
	  			}else{
	  				setTimeout(() => {
	  				  Toast.success(this.$t(res.info));
	  				  this.$router.push("/recharge");
	  				}, 1000);
	  			}
	  			
	  		})
	  	},
	  /**
	   * post方法，对应post请求
	   * @param {String} url [请求的url地址]
	   * @param {Object} params [请求时携带的参数]
	   */
	postdata(url, params) {
		
	    return new Promise((resolve, reject) => {
	      axios
	        .post(url, QS.stringify(params))
	        .then((res) => {
	          resolve(res.data);
	        })
	        .catch((err) => {
	          reject(err.data);
	        });
	    });
	  },
	upLoad(){
	  	this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
	},
	changeImage(){
			   var files = this.$refs.avatarInput.files;
				var that = this;
				function readAndPreview(file) {        
				        //Make sure `file.name` matches our extensions criteria
				        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
				          var reader = new FileReader();
				          reader.onload = function(e) {
				            // 防止重复上传
				            // if (that.imgDatas.indexOf(e.target.result) === -1) {
				            //   that.imgDatas.push(e.target.result);
				            // }
				          };
				          reader.readAsDataURL(file);
				        }
				      }
				readAndPreview(files[0])
				if (files.length === 0) {
				  return;
				}
				
				this.setUploadFile(files[0])
		  },
    setUploadFile(file) {
       Toast.loading({
			duration: 0,
			message: "",
			forbidClick: true,
      	});
      	  		  
		
		var formData = new FormData()
		formData.append(file.name,file) ;// 添加到请求体
		
		postimg('Home/Home/headimg_img', formData)
			
					.then(data=> {
						Toast.clear();
						//let data = JSON.parse(res.bodyText);
						if(data.status == 1){
							this.img = data.info;
							this.dataimg = data.info2;
						}else{
							Toast.fail(this.$t(data.info));
						}
						console.log(data);
			})
    },

    onCopy(text) {
      let clipboard = new Clipboard('.clipboardBtnUrl')
      clipboard.on('success', function (e) {
        Toast(text)
        e.clearSelection()
      })
    },
    confirmEvent() {
      console.log('ceshi')
    },
    getBack() {
      this.$router.back()
    },
    linkEvent() {
      console.log('链接')
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
    },
    init(type) {
      
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 20px 10px 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    // margin-top: 90px;
     width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;
    .card {
      margin-bottom: 40px;
      padding: 50px 26px 40px;
     
      .qrCode {
        text-align: center;
        img {
          width: 200px;
          height: 200px;
        }
      }
      .determine {
        border-radius: 15px;
        padding: 10px 0;
        text-align: center;
        font-size: 12px;
        margin-bottom: 50px;
        background-color: #0d9135;
      }
      .text {
        color: #ffffff;
      }
      .uplod {
        margin: 20px 0;
      }
      .introduce {
        color: #FFFFFF;
		font-size: 14px;
		padding-top: 10px;
      }
    }
    .description {
      color: #0d9135;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  ::v-deep .van-uploader__upload {
    background-color: #2a2c2e;
    border: 1px solid #888;
    border-radius: 10px;
  }
}
</style>
