<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('帮助中心') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <div class="collapseBox">
          <van-collapse
            v-model="activeNames"
            accordion
            v-for="(item, index) in list"
            :key="index"
          >
            <van-collapse-item :title="item.mas" :name="index" class="help">
              <div v-html="item.content"></div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <ROBOT />
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
// import HBBTN from '@/components/hb-btn'
import { apiwenzhang } from '@/http/api/'
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      list: [],
      token: '',
      activeNames: 0
    }
  },
  created() {
    var that = this
    apiwenzhang({}).then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].title == '帮助中心') {
          that.list.push(res[i])
        }
      }
    })
  },
  methods: {
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 20px 10px 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    // background-color: #2a2c2e;
  }
  .card {
  }
  .footerBtn {
    margin-top: 50px;
  }
}
::v-deep .van-cell {
  //   background-color: #2a2c2e;
  background: #2a2c2e !important;
  border-radius: 20px;

  //   border-radius: 20px 20px 0 0;
}
::v-deep .van-collapse-item__content {
  background: #2a2c2e !important;
  border-radius: 20px;
  //   border-radius: 0 0 20px 20px;
}
::v-deep .van-collapse-item {
  border-radius: 20px;
  //   height: 90px;
  background: #2a2c2e !important;
}
.collapseBox {
  margin-bottom: 20px;
}
.help {
  margin-bottom: 30px !important;
  // border: 1px solid red !important;
}
::v-deep .van-collapse-item--border {
  border: none !important;
}
</style>
