<template>
  <div class="radioButton">
    <van-button class="btn" round block type="info" @click="clickEvent">
      <slot></slot>
    </van-button>
  </div>
</template>

<script>
export default {
  methods: {
    clickEvent() {
      this.$emit('clickEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.radioButton {
  width: 350px;
  margin: 0 auto;
  margin-bottom: 40px;
  .btn {
    box-shadow: 0px 10px 20px #0d9135;
    font-size: 30px;
    height: 103px;
    background-color: #0d9135;
    border: none;
    color: #fff;
  }
}
</style>
