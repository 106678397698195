<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('语言切换') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <div class="collapseBox">
		  <div @click="chngelang('en')" class="help" >
			  <span style="height: 30px;">{{$t('English')}}</span>
		  </div>
		  <div @click="chngelang('kr')" class="help" >
		  			  <span style="height: 30px;">{{$t('한국의')}}</span>
		  </div>
		  <div @click="chngelang('th')" class="help" >
		  			  <span style="height: 30px;">{{$t('ภาษาไทย')}}</span>
		  </div>
		  <div @click="chngelang('tr')" class="help" >
		  			  <span style="height: 30px;">{{$t('Türkçe')}}</span>
		</div>
		  <div @click="chngelang('hi')" class="help" >
					  <span style="height: 30px;">{{$t('हिन्दी')}}</span>
		</div>
		  <div @click="chngelang('es')" class="help" >
					  <span style="height: 30px;">{{$t('Español')}}</span>
		</div>
		  <div @click="chngelang('bn')" class="help" >
					  <span style="height: 30px;">{{$t('বাংলা')}}</span>
		</div>
		  <div @click="chngelang('pt')" class="help" >
					  <span style="height: 30px;">{{$t('Português')}}</span>
		</div>
		  <div @click="chngelang('jp')" class="help" >
					  <span style="height: 30px;">{{$t('日本語の')}}</span>
		  </div>
		  <div @click="chngelang('id')" class="help" >
		  			  <span style="height: 30px;">{{$t('Bahasa Indonesia')}}</span>
		  </div>
		  <div @click="chngelang('ar')" class="help" >
		  			  <span style="height: 30px;">{{$t('العربية')}}</span>
		  </div>
		  <div @click="chngelang('zh')" class="help" >
		  			  <span style="height: 30px;">{{$t('简体中文')}}</span>
		  </div>
        </div>
      </div>
    </div>
    <ROBOT />
  </div>
</template>

<script>

import ROBOT from '@/components/robot'
// import HBBTN from '@/components/hb-btn'

export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      list: [],
      token: '',
      activeNames: 0
    }
  },
  created() {
  
  },
  methods: {
	  chngelang(lang){
		this.$i18n.locale = lang;
		console.log(lang);
		this.$router.back()
	  },
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 20px 10px 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    // background-color: #2a2c2e;
  }
  .card {
  }
  .footerBtn {
    margin-top: 50px;
  }
}
::v-deep .van-cell {
  //   background-color: #2a2c2e;
  background: #2a2c2e !important;
  border-radius: 20px;

  //   border-radius: 20px 20px 0 0;
}
::v-deep .van-collapse-item__content {
  background: #2a2c2e !important;
  border-radius: 20px;
  //   border-radius: 0 0 20px 20px;
}
::v-deep .van-collapse-item {
  border-radius: 20px;
  //   height: 90px;
  background: #2a2c2e !important;
}
.collapseBox {
  margin-bottom: 20px;
}
.help {
  margin-bottom: 30px !important;
  // border: 1px solid red !important;
  width: 95%;
  line-height: 50px;
  background-color: #171717;
  color: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 24px;
}
::v-deep .van-collapse-item--border {
  border: none !important;
}
</style>
