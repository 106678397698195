<template>
  <div>
    <layout :isShowFooter="false">
      <template #title>{{ $t('付款方式') }}</template>
      <template #right>
        <img class="addIcon" src="@/assets/icon/add.png" alt="" />
      </template>
      <template #content>
        <div class="nodata flexbox_column a-center">
          <img src="@/assets/icon/nodata.png" alt="" />
          <div class="mt10">{{ $t('暂无数据') }}</div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.addIcon {
  width: 42px;
  height: 42px;
}
.nodata {
  margin-top: 300px;
  img {
    width: 106px;
    height: 132px;
  }
}
</style>
