export default {
  tabbar: {
    首页: 'ホーム',
    市场: '市場',
    任务: 'タスク',
    团队: 'チーム',
    我的: 'わたしの'
  },
  navbar: {
    充值: 'リチャージ',
    提现: '出金',
    帮助: 'ヘルプ',
    分享: 'シェア',
    活动: 'イベント'
  },	 
  payment: {
    付款方式详情: 'お支払い方法の詳細',
    银行卡: '銀行カード',
    钱包地址: 'ウォレットアドレス',
    交易密码: '取引パスワード',
    保存: 'セーブ'
  },
  market: {
    市场: '市場',
    名称: '氏名',
    价格: '価格',
  },
 
  task: {
    任务: 'タスク',
	语言切换: '言語切り替え',
    我的余额: '私のバランス',
    增加订单数量: '注文数量を増やす',
    今日剩余订单: '今日の残りの注文',
    频率: '周波数',
    手动交易: 'マニュアルトレード',
    预期收益: '期待される収入',
    需要启动存款: 'デポジットが必要',
    任务说明: 'タスクの説明',
    每天可以完成10个任务: '1日あたり10個のタスクを完了することができます',
    联系VIP助手增加任务数量: 'タスクの数を増やすには、VIP アシスタントに連絡してください',
    每个用户的任务总数为300次: 'ユーザーあたりのタスクの総数は 300 です',
    购买机器人无需人工操作即可自动交易: 'ロボット購入時も手操作不要の自動取引',
    我知道了: 'わかった!'
  },
  团队: 'チーム',
  规则: 'ルール',
  会员级别: 'メンバーレベル',
  经验: '経験',
  代理信息: 'エージェント情報',
  更多: 'もっと',
  团队奖励: 'チーム報酬',
  昨天: '昨日の',
  本星期: '今週',
  全部: '全て',
  团队收入记录: 'チームの収益記録',
  机器人回扣: 'ロボットリベート',
  会员体验: 'メンバーの経験',
  个人收入记录: '個人所得記録',
  机器人采购: 'ロボット調達',
  机器人启动押金: 'ロボット起動保証金',
  机器人礼物: 'ロボットギフト',
  机器人订单: 'ロボットの注文',
  机器人: 'ロボット',
  一次性总和: '一時金',
  总计: '合計',
  我的机器人: '私のロボット',
  被激活: 'アクティブ化',
  工作中: 'ワーキング',
  暂停: '止まる',
  结束: '終わり',
  刷新: 'リフレッシュ',
  来源: 'ソース',
  价格: '価格',
  启动押金: '開始価格',
  剩余时间: '残り時間',
  激活时间: '起動時間',
  号: 'いいえ。',
  我的资产: '私の資産',
  退出: 'ログアウト',
  邀请码: '招待コード',
  我的钱包余额: '私の財布の残高',
  我的机器人仓库: '私のロボット倉庫',
  代理信息: 'エージェント情報',
  去提款: '出金',
  昨天代理返利: '昨日の代理店リベート',
  累计代理返利: '累計代理店リベート',
  今天机器人收益: '今日の収益',
  累计机器人收益: '累積収入',
  累计机器人回扣: '累積リベート',
  累计总回报: '累積トータルリターン',
  自动订单机器人数量: '自動発注ロボット台数',
  我的付款方式: '私の支払い方法',
  个人收入记录: '個人所得記録',
  团队收入记录: 'チームの収益記録',
  充值记录: 'リチャージ記録',
  我的团队成员: '私のチームメンバー',
  活动中心: 'イベントセンター',
  修改登录密码: 'ログインパスワードの変更',
  交易密码管理: '取引パスワード管理',
  机器人: 'ロボット',
  机器人性能: 'ロボット性能',
  机器人价格: 'ロボット価格',
 
  有效期: '有効期間',
  
  天: '日々',
  去购买: '購入する',
  买个机器人: 'ロボットを購入',
 
  预计日收益: '推定日収',
  启动保证金: '起動マージン',
  请输入购买数量: '購入数量を入力してください',
  个机器人: ' 個/ロボット',
  机器人购买后需要激活才能工作: '購入後、ロボットを作動させるにはアクティベートが必要です',
  机器人激活需要从余额中扣除相应的启动押金:
    'ロボットのアクティベーションでは、対応するスタートアップ デポジットを残高から差し引く必要があります。',
  机器人暂停机器人停止时将返还启动押金:
    'ロボットが停止すると、初期保証金が返金されます',
	不激活的机器人可以赠送:"非アクティブなロボットは譲ることができます",
  购买: '購入',
  点击去了解我们: 'クリックして私たちについて学ぶ',
  购买机器人: 'ロボットを購入',
  快速入口: 'クイック入力',
  '日/个人受益': '日割り・個人特典',
  购买教程: '購入チュートリアル',
  订单数量: '注文数量',
  我们的合作伙伴: '私たちのパートナー',
  提款: '出金',
  提款金额: '出金金額 ',
  你还没有添加银行卡: '銀行カードを追加していません',
  提现说明: '出金の説明',
  确认提款: '出金を確認する ',
  钱包余额: 'ウォレットの残高',
  输入数量:"USDTの金額を入力",
  数量:"金額",
  登录: 'ログイン',
  输入账号: '口座番号を入力',
  输入密码: 'パスワードを入力',
  还没有账号点击注册: 'アカウントがありませんか？ 登録をクリック',
  忘记密码: 'パスワードを忘れた',
  重新登录: '再ログイン',
  密码找回成功: 'パスワードが正常に取得されました',
  找回成功请重新登录: '正常に取得されました。再度ログインしてください',
  确认: '確認する',
  发送验证码: '確認コードを送信',
  再次输入密码: 'パスワードをもう一度入力してください',
  输入验证码: '認証コードを入力してください',
  输入手机号: '携帯番号を入力',
  注册: '登録する',
  输入用户名: 'ユーザー名を 1 つ入力してください',
  请输入邀请码: '招待コードを入力してください',
  返回: '戻る',
  密码找回失败: 'パスワードの取得に失敗しました',
  输入的信息有误: '誤った情報が入力されました',
	
	获取验证码:"コードを取得",
	手机号码不能为空:"携帯電話番号を空にすることはできません",
	账号不存在:"アカウントが存在しません",
	发送成功:"正常に送信されました",
	今日收益:"今日の収益",
	
	日收益:"日々の収入",
	
	数量不能为空:"数量を空にすることはできません",
	加载中:"読み込み中",
	唯一码:"固有コード",
	未激活:"非活動中",
	激活:"活性化",
	购买时间:"購入時期",
	"钱包余额不足，激活该机器人失败":"ウォレットの残高が不足しているため、ロボットを起動できませんでした",
	暂无数据:"データなし...",
	激活时间:"起動時間",
	运行时长:"実行時間",
	签约购买:"契約購入",
	系统赠送:"システムギフト",
	状态:"ステート",
	正常:"ノーマル",
	我的机器人:"私のロボット",
	一级会员:"レベル1",
	二级会员:"レベル2",
	三级会员:"レベル3",
	人:"人々",
	充值客服:"リチャージカスタマーサービス",
	充值:"リチャージ",
	提现:"出金",
	设置交易密码:"取引パスワードの設定",
	登录密码:"ログインパスワード",
	请输入交易密码:"取引パスワードを入力してください",
	再次请输入交易密码:"取引パスワードをもう一度入力してください",
	确认: '確認する',
	手机号码不能为空:"携帯電話番号を空にすることはできません",
	两次密码不一致:"2 つのパスワードが一致していません",
	请输入验证码:"確認コードを入力してください",
	操作成功:"操作は成功しました",
	"用户名或密码不正确,登录失败":"ユーザー名またはパスワードが正しくないため、ログインに失敗しました",
	登录成功:"ログイン成功",
	充值说明:"入金の指示",
	请先设置支付密码:"最初に支払いパスワードを設定してください",
	复制成功:"コピー成功",
	冻结机器人做单本金:"ロボット注文の元金凍結",
	待审核:"ペンディング",
	成功:"成功しました",
	失败:"失敗します",
	审核中:"レビュー中",
	在线充值:"オンラインリチャージ",
	描叙:"説明する",
	银行卡提现:"銀行カードの引き出し",
	USDT提现:"USDTの出金",
	三级代理:"第 3 レベルのエージェント",
	一级代理:"一次エージェント",
	二级代理:"二次エージェント",
	做单:"注文する",
	团队返佣: "チームリベート",
	购买机器人返佣:"ロボット リベートを購入する",
	本金返还:"元本の返還",
	时间:"時間",
	机器人做单返还:"ロボットが注文して返却",
	涨幅:"見積もり変更",
	
  付款方式:"支払方法",
	我的留言:"私のメッセージ",
  
  银行卡:'バンクカード',
  修改: '変更',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'間違ったアドレス入力によりお金を受け取ることができないユーザーによる損失を防ぐために、ウォレットアドレスが正しいことを確認してください',
 连接借记卡:"デビットカードを接続する",
 银行名称:'銀行名',
 姓名姓氏: '氏名',
 IBAN号码:'銀行カード番号',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 重要: 出金前にデビットカード情報が真実かつ有効である必要があります.',
 帮助中心:'ヘルプセンター',
 会员须知:'メンバーへの注意事項',
 '24小时内使用USDT实时取款（推荐）':'1. 24時間以内に米ドルでリアルタイム出金（推奨）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. 銀行カードの引き出し * 引き出し時間は 10:00-18:00 * 引き出し開始後 2 時間以内の口座入力 * 引き出し不可時間または銀行間引き出しの申請は、翌日 10:00-18:00 に受信されます',
 我知道了:'わかった!',
 链接点击:'リンククリック',
 
 建立自己的团队邀请:'独自のチーム招待状を作成する',

 加入:'加入',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "販売したロボットが相手方に受け入れられました。 売上金はあなたの口座に支払われました。 チェックしてください.",
 	"你出售的机器人对方已拒绝接受":"販売したロボットが相手に断られました",
 	向你赠送一台机器人:"確認のためにロボットが提示されます",
 	向你出售一台机器人:"あなたにロボットを売る、確認する",
 	出售价格不能超过机器人价格:"販売価格はロボットの価格を超えることはできません",
 	银行卡已被绑定:"銀行カードがバインドされました",
 	USDT_TRC20提现:'USDT_TRC20 出金',
 	USDT_ERC20提现:'USDT ERC20 出金',
 	银行卡提现:'銀行カードの引き出し',
 	邀请赠送:'ご招待ギフト',
 	机器人返佣:'ロボットコミッション',
 	升级:'アップグレード',
 	充值主网:'メインネットワークの再充電',
   "您被限制出售机器人,请联系客服了解详情":
     "ロボットの販売は制限されています。 詳しくはカスタマーサービスまでお問い合わせください",
   交易编号: "取引番号",
   消息详情:"メッセージの詳細",
   个人收益累计:"累計個人所得",
   今日代理收益:"今日の代理店収入",
   代理累计收益:"代理店収入累計",
  
   截图保存推荐给朋友:"スクリーンショットを保存して友達に勧める",
   复制:"コピー",
   
   请输入正确的充值金额:"正しいチャージ金額を入力してください",
   推荐:"お勧め",
   充值金额:"チャージ金額",
   请上传付款截图:"支払いのスクリーンショットをアップロードしてください",
   充值ID提示:"リチャージ ID プロンプト",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"ウォレットアドレスが正しいことを確認してください。 ウォレットアドレスが間違って入力された場合、ユーザーはお金を回収できないことによる損失を負担します.",
	上传凭证:"バウチャーをアップロード",
	不能为空:"空にすることはできません",
  绑定钱包地址:'バインドウォレットアドレス',
	
	备注:"備考",
	请输入你的名字:"あなたの名前を入力してください",
	请输入银行卡号:"銀行カード番号を入力してください",
	银行卡号:"銀行カード番号",
	添加银行卡:"銀行カードを追加",
	请选择银行:"銀行を選択してください",
	请输入钱包地址:"ウォレットアドレスを入力してください",
	钱包地址:"ウォレットアドレス",
	"取款须知":"出金のご案内",
	"须知1":"1. 24時間以内に米ドルでリアルタイム出金（推奨）",
	"须知2":"2.バンクカード",
	"须知3":"出金 ※出金時間は10:00～18:00",
	"须知4":"*非出金時間または銀行間出金の申請は、翌日の10:00-18:00に受信されます",
	
	已绑定:"バウンド",
	去设置:"セッティング",
	汇率:"為替レート",
	银行:"バンク",
	请输入正确的提现金额:"正しい出金金額を入力してください",
	提款记录:"出金記録",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"ヒント 1:バインドされたウォレット アドレスは、支払いウォレットと一致している必要があります。一致していないと、受信されません。。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"ヒント2:リチャージが成功してから約1分で到着する予定です。 ウォレットを確認してください.",
	请先绑定钱包地址:"最初にウォレットアドレスをバインドしてください",
	输入邮箱:"Gmail に入る",
	指定用户不存在:"指定されたユーザーは存在しません",
	今日涨幅:"今日の変化",
	快捷充币:"クイック入金",
	快速买USDT:"USDTをクイック購入",
	在线支付:"オンライン支払い",
	自动到账:"自動領収書",
	线下支付:"オフライン決済",
	联系客服获取验证码:"確認コードについては、カスタマー サービスにお問い合わせください",
	获取:"取得する",
	重新登录:"再ログイン",
	退出成功:"正常に終了します",
	不能低于最小充值:"最小リチャージより低くすることはできません",
	不能低于最小提现金额:"最低出金額以上",
	最小提现:"最低出金額",
	官方充币:"正式な課金",
	银行卡充值:"銀行カードのリチャージ",
	等待审核:"審査待ち",
	可提现金额不足:"出金額が足りない",
	未经授权:"無認可",
	交易密码不正确:"取引パスワードが正しくありません",
	提现次数不足:"不十分な引き出し時間",
	需要更新的银行卡不存在:"更新する銀行カードが存在しません",
	银行卡提现:"銀行カードの引き出し",
	激活金额:"アクティベーション量",
	'赠送/出售':"与える/売る",
	赠送:"与える",
	出售:"売る",
	账户usdt不足:"バランスの欠如",
	请输入提现金额:"出金金額を入力してください",
	恭喜新用户:"新規ユーザーの皆様、おめでとうございます ",
	kefu1:"Youtube ビデオチュートリアル",
	登录密码:"ログインパスワード",
	再次请输入交易密码:"取引パスワードをもう一度入力してください",
	输入手机号码:"電話番号",
	输入手机号码:"電話番号を入力",
	输入密码:"パスワード",
	请输入密码:"パスワードを入力する",
	手机号格式错误:"携帯番号のフォーマットエラー",
	"密码错误次数过多,请等待x秒后再试":"パスワード エラーが多すぎます。600 秒待ってからもう一度お試しください",
	"此账号已冻结,请联系客服":"このアカウントは凍結されています。カスタマー サービスにお問い合わせください",
	登录失败:"ログインに失敗しました",
	请勿重复操作:"繰り返さない",
	邀请码不正确:"招待コードが正しくありません",
	此账号已冻结:"このアカウントは凍結されました",
	请输入手机号:"携帯電話番号を入力してください",
	验证码错误:"確認コード エラー",
	手机号存已存在:"携帯電話番号は既に存在します",
	注册账号已存在:"登録済みアカウントは既に存在します",
	请确定新密码:"新しいパスワードを確認してください",
	请再次输入登录密码:"ログインパスワードをもう一度入力してください",
	密码长度不能少于6位:"パスワードの長さは 6 桁未満にすることはできません",
	
	输入邮箱:"Gmail に入る",
	注册邮箱已存在:"登録 Gmail は既に存在します",
	请输入用户ID:"ユーザーIDを入力してください",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"相手方の口座名義を入力して物件を譲渡する場合は、記載内容をよくご確認ください。 操作エラーが発生した場合、システムまたはプラットフォームの顧客サービスを通じて取得できません.",
	请输入出售金额:"売却金額を入力してください",
	出售中:"ギフトが送信されました。確認待ちです",
	 收到一台系统赠送的机器人 :"システムからロボットを受け取りました",
	 会员出售:"会員販売",
	 "出售待确认中,请勿重复操作":"確認保留中の販売、操作を繰り返さないでください",
	 激活成功:"成功を活性化する",
	 操作成功:"操作は成功しました",
	
	输入邮箱:"Gmail に入る",
	
	请输入登录密码:"ログインパスワードを入力してください",
	未满足条件:"条件が満たされていない",
	活动已过期:"アクティビティの有効期限が切れました",
	"邀请好友注册，领取佣金":"友達を登録してコミッションを受け取るように招待する",
	领取:"受け取る",
	团队规则:"チームルール",
	邮箱已存在:"Gmail は既に存在します",
	获取邮箱验证码:"コードを取得",
	请输入邮箱:"メールアドレスを入力してください",
	手机号码:"電話",
	邮箱不能为空:"電子メールを空にすることはできません",
	"出售/赠送":"販売 / 贈り物",
	已接受:"受け入れられた",
	"出售/赠送人":"売り手 / 与える",
	"向你赠送一台机器人，待确认":"確認のためにロボットが提示されます",
	国码:"国コード",
	取消:"キャンセル",
	银行卡号位数错误:"銀行カードの枚数が間違っている",
	官网:"オフィシャルサイト",
	注册成功:"ログイン成功",
	设置成功:"正常に設定されました",
	手机号码已存在:"携帯電話番号は既に存在します",
	修改成功:"正常に変更されました",
	立即登录:"すでにアカウントをお持ちの場合は、クリックしてログインしてください",
	客服:"チャット",
	邮箱:"Eメール",
	机器人量化AI交易收益:"ロボットが AI 取引収入を数値化",
	体验机器人不能赠送:"体験ロボットは譲れません",
	接受:"受け入れる",
	拒绝:"拒否します",
	语言切换:"言語切り替え",
	
	
	投资:"投資",
	投资理财:"投資と財務管理",
	投资金额:"投資額",
	我的投资:"私の投資",
	投资返佣:"投資リベート",
	投资订单:"投資注文書",
	运行中:"ランニング",
	冻结投资做单本金:"投資注文元本を凍結",
	投资做单返还:"投資注文収益率",
	"钱包余额不足，启动投资失败":"ウォレット残高が不足しており、投資を開始できませんでした",
	收到一个系统赠送的投资产品:"システムによって与えられた投資商品を受け取りました",
	购买投资:"投資の購入",
	购买投资增加经验值:"投資増加経験値購入",
	下级购买投资增加经验值:"部下が投資を購入すると経験値が増加しました",
	出售价格不能超过投资价格:"販売価格は投資価格を超えることはできません",
	"向你赠送一个投资产品，待确认":"投資商品を提供します、確認待ちです",
	"向你出售一个投资产品，待确认":"投資商品を販売します、確認待ちです",
	"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收":"相手方はあなたが販売した投資商品を受け入れ、販売収益はあなたの口座に送金されました。ご確認ください",
	你出售的投资产品对方已拒绝接受:"あなたが販売した投資商品の受け取りを相手が拒否しました",
	赠送投资:"無償投資",
	投资产品:"投資商品",
	请选择投资产品:"投資商品を選択してください",
	自动投资产品数量:"自動投資商品の数",
	
	"youtube活动内容":"ボーナスに関する重要なお知らせ：\n1. ユーザーは、自身のYouTubeやFacebookなどのチャンネルに動画を録画してアップロードすることで、1～200ドルのボーナスを受け取ることができます。\n2. プラットフォームはあなたの動画を審査し、動画の品質に基づいて広告収入をアカウント残高に入金します。\n3. 同じチャンネルと同じ広告動画は一度しかボーナスを受け取ることができませんが、毎週新しい動画をアプリに公開することができます。\n4. 悪意のある動画報酬のリクエストは禁止されています。悪意のある報酬リクエストを提出した場合、アカウントが禁止されます。",
	"Bono de YOUTUBE":"YouTube特典",
	"Publicar video bono 10 USDT - 200 USDT":"公開ビデオ ボノ 10 USDT - 200 USDT",
	"Ingrese el monto de la solicitud":"リクエスト金額を入力してください",
	"Enlace de tu video":"あなたのビデオリンク",
	"Ingrese el enlace del video post-publicado":"post-publicado ビデオへのリンクを入力してください",
	"Aplicar para un premio":"賞品の応募",
	"Solicitar una bono":"クーポンのリクエスト",
	"URL del vídeo":"ビデオのアドレス",
	"Tiempo de aplicación":"申請時間",
	"个人充值红利活动":"個人リチャージボーナスアクティビティ",
	
	"注册证书":"登録証明書",
}
