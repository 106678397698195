<template>
  <layout>
	  
    <template #left> <span></span></template>
    <template #title>{{ $t('团队') }}</template>
    <template #right>
      <div @click="handleRules">{{ $t('规则') }}</div>
    </template>
    <template #content>
      <div class="content">
      
        <div class="flexbox j-center">
          <div class="info mt20 bcolor">
            <div class="mt23 ml32 lv flexbox a-center">
              <span>{{ $t('会员级别') }}</span>
              <img
                class="vipIcon ml10 mr10"
                src="@/assets/icon/vip.png"
                alt=""
              />
              <span class="bold bcolor">LV{{classlver}}</span>
            </div>
            <div class="flex_row_space-between mt50">
              <div class="ml32">
                <span>{{ $t('经验') }}</span>
                <span class="ml20 bold">{{ leverexp }}/{{xjleverexp}}</span>
              </div>
              <div class="mr32 bold">{{bili}}%</div>
            </div>
            <div class="progressBg ml32 mr32 mt20">
              <div class="progress"></div>
            </div>
          </div>
        </div>
        <div class="dlinfo ml14 mr14">
          <div class="flex_row_space-between">
            <div class="ml32 f30">{{ $t('代理信息') }}</div>
            <div class="mr32" @click="goDaili()">
              <span class="ccolor mr10">{{ $t('更多') }}</span>
              <img class="jiantou-w" src="@/assets/icon/jiantou-w.png" alt="" />
            </div>
          </div>
          <div class="mt42">
            <div class="ml32 sc" style="overflow-x: scroll">
              <div class="infoItem">
                <div class="ml52 mt20 hide">
                  <div class="f24 mt30">{{ $t("一级会员") }}</div>
                  <div class="mt20">
                    <span class="f30 bold">{{ teamdata.yiji_count }} </span>
                    <span class="ml10">{{ $t("人") }}</span>
                  </div>
                </div>
				
              </div>
			  <div class="infoItem">
			    <div class="ml52 mt20 hide">
			      <div class="f24 mt30">{{ $t("二级会员") }}</div>
			      <div class="mt20">
			        <span class="f30 bold">{{ teamdata.erji_count }}</span>
			        <span class="ml10">{{ $t("人") }}</span>
			      </div>
			    </div>
			  				
			  </div>
			  <div class="infoItem">
			    <div class="ml52 mt20 hide">
			      <div class="f24 mt30">{{ $t("三级会员") }}</div>
			      <div class="mt20">
			        <span class="f30 bold">{{ teamdata.sanji_count }}</span>
			        <span class="ml10">{{ $t("人") }}</span>
			      </div>
			    </div>
			  				
			  </div>
            </div>
          </div>

          <div>
            <div class="flex_row_space-between mt50">
              <div class="ml32 f30">{{ $t('团队奖励') }}</div>
              <router-link to="/personalIncome" class="mr32">
                <span class="ccolor mr10">{{ $t('更多') }}</span>
                <img
                  class="jiantou-w"
                  src="@/assets/icon/jiantou-w.png"
                  alt=""
                />
              </router-link>
            </div>
            <div class="ml32 mr32 award flexbox">
              <div class="flex1 flexbox_column center">
                <div>{{ $t('昨天') }}</div>
                <div class="mt10 f30 bold">{{ teamdata.today }}</div>
              </div>
              <div class="flex1 flexbox_column center">
                <div>{{ $t('本星期') }}</div>
                <div class="mt10 f30 bold">{{ teamdata.month }}</div>
              </div>
              <div class="flex1 flexbox_column center">
                <div>{{ $t('全部') }}</div>
                <div class="mt10 f30 bold">{{ teamdata.total }}</div>
              </div>
            </div>
          </div>
        </div>
		<ROBOT />
      </div>
    </template>
  </layout>
</template>

<script>
	import { apigetagent, apiteamData, apilerverdata } from "@/http/api/";
import { Toast } from 'vant'
import ROBOT from '@/components/robot'
export default {
	components: {
	  
		ROBOT,
	
	},
	  data() {
	    return {
	      swiperOption: {
	        slidesPerView: "auto",
	        centeredSlides: true,
	        spaceBetween: 15,
	        loop: false,
			
	      },
	      classlver: "tm-preview-card tm-level-1",
	      levername: "",
	      agent_list: [],
	      list: [],
	      teamdata: [],
	      leverexp: 0,
	      xjleverexp: 0,
	      bili: 0,
		  bili2:0,
		  bili3:0,
		  bili4:0,
		  bili5:0
	    };
	  },
  methods: {
		goDaili(){
			this.$router.push('/teaminfo')
		},
		goTeamdata(){
				this.$router.push('/teamRules')
		},
    handleRules() {
      this.$router.push('/teamRules')
    },
	init() {
		Toast.loading({
		  duration: 0,
		  message: this.$t('加载中'),
		  forbidClick: true,
		});
	      var that = this;
	      apigetagent({
	        userid: this.token,
	        page: 1,
	        type: 0,
	      }).then((res) => {
				Toast.clear();
				console.log(res);
				for (let i = 0; i < res.info.length; i++) {
				  that.list.push(res.info[i]);
				}
	      });
	    },
  },
  
  created() {
      var token = localStorage.getItem("key");
      console.log("token", token);
      if (!token) {
        this.$router.replace("/login");
      }
      this.token = token;
      apiteamData({
        userid: this.token,
      }).then((res) => {
  		if(res.status == 10){
  			this.$router.replace("/login");
  		}
        this.teamdata = res.info;
        console.log(res.info);
      });
      apilerverdata({
        userid: this.token,
      }).then((res) => {
        var res = res.info;
        this.levername = res.name;
        this.classlver =  res.level_tuandui;
        this.leverexp = res.jyzhi;
        this.xjleverexp = res.xj_jyzhi;
  	  var  xj_jyzhi=0;
  	  if(res.xj_jyzhi==0)
  	  {
  		 xj_jyzhi=res.jyzhi;
  	  }else
  	  {
  		  xj_jyzhi=res.xj_jyzhi;
  	  }
  		this.bili =  ( res.jyzhi/xj_jyzhi*100).toFixed(2);
      });
  
      this.init();
    },
}
</script>

<style lang="scss" scoped>
.content {
  .msg {
    height: 100px;
    background: #2a2c2e;
    border-radius: 50px 50px 50px 50px;
    .msg-icon {
      width: 19px;
      height: 28px;
    }
  }
  .info {
    width: 720px;
    height: 306px;

    background-image: url('@/assets/icon/info-bg.png');
    background-size: 720px 306px;
    background-repeat: no-repeat;
    .lv {
      height: 50px;
    }
    .vipIcon {
      width: 24px;
      height: 24px;
    }
    .progressBg {
      height: 16px;
      background: rgba($color: #1b1b1b, $alpha: 0.34);
      border-radius: 8px 8px 8px 8px;
      /* opacity: 0.3448; */
      position: relative;
      .progress {
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        height: 16px;
        background: linear-gradient(
          90deg,
          #1a1a1a 4%,
          rgba(26, 26, 26, 0.3799999952316284) 100%
        );
        border-radius: 8px 8px 8px 8px;
        z-index: 999;
      }
    }
  }
  .dlinfo {
    background: rgb(77, 77, 77);
    border-radius: 59px 59px 0px 0px;
    padding: 50px 0;
    .sc {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      flex-wrap: nowrap;
    }
    .infoItem {
      min-width: 302px;
      min-height: 202px;
      width: 302px;
      height: 202px;
      background: url('@/assets/icon/msg-bg.png') no-repeat;
      background-size: 302px 202px;
    }
    .award {
      height: 150px;
      background: linear-gradient(180deg, #2a2c2e 4%, #292929 100%);
      box-shadow: 0px 18px 20px 1px rgba(0, 0, 0, 0.30000001192092896);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 23px;
    }
  }
}
</style>
