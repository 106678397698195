<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('payment.付款方式详情') }}</div>
      <div></div>
    </div>
    <div class="content">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="USDT (ERC20)" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="USDT (TRC20)" clickable @click="radio = '2'">
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell
            :title="$t('payment.银行卡')"
            clickable
            @click="radio = '3'"
          >
            <template #right-icon>
              <van-radio name="3" />
            </template>
          </van-cell>
        </van-cell-group>
        <div class="address">{{ $t('payment.钱包地址') }}</div>
        <van-cell-group>
          <van-cell title="100032" clickable @click="radio = '4'">
            <template #right-icon>
              <van-radio name="4" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell
            :title="$t('payment.交易密码')"
            clickable
            @click="radio = '5'"
          >
            <template #right-icon>
              <van-radio name="5" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="save">
      <HBBTN @clickEvent="confirmEvent">
        {{ $t('payment.保存') }}
      </HBBTN>
    </div>
  </div>
</template>

<script>
import HBBTN from '@/components/hb-btn'

export default {
  components: {
    HBBTN
  },
  data() {
    return {
      radio: '1'
    }
  },
  methods: {
    confirmEvent() {
      console.log('dfadf')
    },
    getBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 20px 40px 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .content {
    padding: 0 30px;
    margin-top: 60px;
  }
  ::v-deep .van-cell-group {
    padding: 5px;
    background-color: #171717;
    border-radius: 20px;
    margin-bottom: 20px;
    height: 100px;
    border: 1px solid #4c4e50;
  }
  .address {
    color: #888;
    margin: 30px 0 10px;
  }
  .save {
    margin-top: 90px;
  }
  ::v-deep .van-cell {
    line-height: 60px;
  }
  ::v-deep .van-cell__title {
    color: #fff;
  }
  ::v-deep .van-cell--clickable {
    background-color: #171717;
  }
}
</style>
